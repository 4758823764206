import { useTranslation } from 'next-i18next';
import {
  Avatar, RatingScore, Tooltip, LinkStyled,
} from '@components/common';
import { CommentAltChecked, MedalSolidIcon } from '@components/common/Icons';

// Interface
// ************************************
export interface CompanyShortProps {
  employer?: ApiSchema<'JobEmployerRead'>,
  workplace?: ApiSchema<'JobWorkplaceRead'>
}

/**
 * @description Job Detail Company Short.
 * - **employer** - ApiSchema<'JobEmployerRead'>
 * - **workplace** - ApiSchema<'JobWorkplaceRead'>
 */
export const CompanyShort = (props: CompanyShortProps) => {
  // Destructure props
  const { employer, workplace } = props;

  // Translation
  const { t } = useTranslation('common');

  // Conditions for showing badges
  // *****************************************
  const showBadge = {
    premium: workplace ? workplace.premium : employer?.premium,
    responsive: workplace
      ? (!workplace.premium && workplace.responsive)
      : (!employer?.premium && employer?.responsive),
  };

  // Helper variables
  const companyName = workplace ? workplace.name : employer?.employerName;
  const companyLogo = workplace ? workplace.logoUrl : employer?.employerLogo;
  const showRating = workplace ? workplace.showRating : employer?.showRating;
  const rating = workplace ? workplace.rating : employer?.rating;

  // Helper classes
  // *****************************************
  const badgeIconClass = 'w-3.5 h-3.5 fill-current';

  // Render component
  // ************************************
  return (
    <div className="mt-10 flex items-center">
      {/* Avatar */}
      <div className="mr-4 shrink-0">
        <a href="#company-widget-box" aria-label={companyName}>
          <Avatar
            imgPath={companyLogo}
            imgFolder="employer_logo"
            extractImageName
            name={(companyName ?? t('employer.name.confidential'))}
            size="md"
            aspectRatio="landscape"
            id={(companyName ?? t('employer.name.confidential')).charCodeAt(0)}
            withRing
          />
        </a>
      </div>
      <div className="flex-1">
        <div className="flex items-center">
          {/* Name */}
          <h3 className="font-bold">
            {workplace ? (
              <LinkStyled
                href={`/company-profile/${workplace.slug}`}
                styling="text"
                className="hover:!no-underline"
              >
                {workplace.name}
              </LinkStyled>
            ) : (
              employer?.employerName ?? t('employer.name.confidential')
            )}
          </h3>

          {/* Premium / responsive badges */ }
          {showBadge.premium && (
            <div className="ml-2 text-primary">
              <Tooltip
                colorScheme="black"
                position="top"
                displayStyle="block"
                tooltipText={t('premium_talent_search_text')}
              >
                <MedalSolidIcon className={badgeIconClass} />
              </Tooltip>
            </div>
          )}
          {showBadge.responsive && (
            <div className="ml-2 text-secondary">
              <Tooltip
                colorScheme="black"
                position="top"
                displayStyle="block"
                tooltipText={`${t('employer.card.responsive')}: ${t('responsive.employer.badge.description')}`}
              >
                <CommentAltChecked className={badgeIconClass} />
              </Tooltip>
            </div>
          )}
        </div>

        {/* Rating */
          showRating && rating && (
            <div className="mt-1">
              <RatingScore score={rating} showScore className="size-4 text-warning" />
            </div>
          )
        }
      </div>
    </div>
  );
};
