import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { useTranslation } from 'next-i18next';
import { Avatar, LinkStyled } from '@components/common';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { detailSectionsStyles } from '@components/templates/job/detail/detailSectionStyles';
import { getI18nLinkHref } from '@services/i18nPaths';
import { JobListType, JobQueryParams } from '@type/job-params';
import { buildJobDetailUrlParams } from '@utils/job/buildJobDetailUrlParams';
import { JOB_DETAIL_SIMILAR_JOBS_LIMIT } from 'src/constants';

// Interface
// ************************************
export interface SimilarJobsProps {
  jobSlug: string;
  similarJobs: ApiSchema<'JobResult'>;
  locale: Locale;
  isModal? : boolean;
}

/**
 * @description Job Detail Similar jobs.
 * - **jobSlug** - string - The job slug.
 * - **similarJobs** - ApiSchema<'JobResult'> - The list of similar jobs.
 * - **isModal** - boolean - Whether it is if opened in a modal context or not. If true, then the similar job will open in a new tab.
 *
 */
export const SimilarJobs = (props: SimilarJobsProps) => {
  // Destructure props
  const {
    jobSlug,
    similarJobs,
    locale,
    isModal,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Next Router
  const { params } = useExtendedRouter();

  // Build search url params to be attached to similar job link
  const urlSearchParams = buildJobDetailUrlParams({
    requestId: similarJobs.apiRequestId,
    position: params && typeof params[JobQueryParams.POSITION] === 'string' ? params[JobQueryParams.POSITION] : undefined,
    listType: JobListType.JOB_DETAIL_SIMILAR_LIST,
  });

  function getJobTitle(job: ApiSchema<'JobItem'>) {
    return Array.isArray(job.translations) && job.translations.length > 0
      ? job.translations.find(
        (translation) => (translation.locale === locale && translation.field === 'title'),
      )?.content
      : job.title;
  }

  // Return null if no similar jobs
  // ************************************
  if (!similarJobs?.items?.length) {
    return null;
  }

  // Render component
  // ************************************
  return (
    <div className={detailSectionsStyles.common} id="similar">
      <h3 className={detailSectionsStyles.title}>
        { t('job.label.custom_similar_jobs_projects') }
      </h3>
      <div className="divide-y divide-input">
        {similarJobs.items.map((similarJob) => (
          <div key={similarJob.id} className="py-4">
            <div className="relative flex rounded px-2 py-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-surface-200 hover:bg-surface-50">

              {/* Link to job details page */
                similarJob.slug && (
                  <LinkStyled
                    prefetch={false}
                    styling="none"
                    href={`${getI18nLinkHref(`/job/${similarJob.slug}`, locale, false)}?${urlSearchParams.toString()}`}
                    className="absolute inset-0 z-1"
                    target={isModal ? '_blank' : undefined}
                  >
                    <span className="opacity-0">{getJobTitle(similarJob)}</span>
                  </LinkStyled>
                )
              }

              <div className="grow">

                {/* Job Title */}
                <h5 className="mb-1 text-lg font-semibold text-primary">{getJobTitle(similarJob)}</h5>

                {/* Company Name & Job Location */}
                <div className="text-sm text-ink-medium">
                  {similarJob.companyName}
                  {(!!similarJob.locations?.length) && (
                    <>
                      {similarJob.companyName && <span className="px-1">|</span>}
                      {similarJob.locations.slice(0, 5).map((location) => location.name).join(', ')}
                    </>
                  )}
                </div>

                {/* Salary */
                  similarJob.salary && <div className="text-sm text-ink-medium">{similarJob.salary}</div>
                }
              </div>

              {/* Company Logo */}
              <div className="ml-3 flex shrink-0 items-center">
                <Avatar
                  imgPath={similarJob.companyLogo}
                  imgFolder="employer_logo"
                  extractImageName
                  name={similarJob.companyName}
                  size="md"
                  aspectRatio="landscape"
                  id={(similarJob.companyName ?? t('employer.name.confidential')).charCodeAt(0)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      {(similarJobs.total ?? 0) > JOB_DETAIL_SIMILAR_JOBS_LIMIT && (
        <div className="border-t border-input pt-6">
          <LinkStyled
            href={`/job/${jobSlug}/similar`}
            styling="none"
            className="inline-flex h-10 w-full items-center justify-center rounded-full text-sm font-medium text-primary outline-none transition-all duration-200 hover:bg-surface-100 hover:shadow"
          >
            <EllipsisHorizontalIcon className="mr-1 size-6" />
            {t('job.detail.react.similar.jobs.see.all', { count: similarJobs.total })}
          </LinkStyled>
        </div>
      )}
    </div>
  );
};

