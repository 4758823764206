// Credits order price calculation ****************************************************************
// pathname: /v1/order/calculate-price
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiOrderCreditsPost } from '@type/v1-api-types';

/**
 * @description Hook interface.
 */
interface UseSwrOrderCalculatePrice {
  locale: string,
  postData?: ApiOrderCreditsPost,
  fetchCondition?: boolean,
}


/**
 * @description SWR: fetch order price calculation. The hook will automatically fetch the data when
 * the postData changes (mutation). It will not fetch anything if the postData is undefined or input value is less than
 * zero. We leverage the dedupingInterval to avoid fetching the same data (postData key) multiple times and use the data
 * already cached.
 * - `locale` string - the locale to use for the request. Default 'ro'.
 * - `postData` ApiOrderCreditsPost - the data we want to send to the server. Default undefined.
 * - `fetchCondition` boolean - the condition to fetch the data. Default undefined.
 */
export const useSwrOrderCalculatePrice = (props: UseSwrOrderCalculatePrice) => {
  // Destructure props
  const {
    locale,
    postData,
    fetchCondition,
  } = props;

  // SWR wrapper
  const {
    data, error, isValidating, isLoading, mutate,
  } = useSwrApi<ApiSchema<'PriceObject'>>({
    url: '/v1/order/calculate-price',
    locale,
    // We will fetch the data conditionally to avoid unneeded requests.
    shouldFetch: fetchCondition,
    fetcherConfig: {
      method: 'POST',
      payload: postData,
      stringifyPayload: true,
    },
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // return data
  return {
    data,
    error,
    isValidating,
    isLoading,
    mutate,
  };
};

