import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Anchor, Button, Tooltip } from '@components/common';
import { ApiNavigationJob } from '@type/v1-api-types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import { AnchorBaseColors } from '@type/anchors-buttons';

// Interface
// ************************************
interface NavButtonProps {
  disabled?: boolean,
  direction: 'prev' | 'next',
  navigationJob?: ApiNavigationJob,
  onClick?: () => void;
  className?: string;
  color?: AnchorBaseColors;
}

/**
 * @description NavButton. Component that renders the nav button for ShareAndNavigation component.
 * - **disabled** -boolean - Whether the button is disabled or not.
 * - **direction** - 'prev' | 'next' - The navigation direction.
 * - **navigationJob** - ApiNavigationJob - The navigation job object.
 * - **onClick** - function - Callback to execute when the button is clicked (to be used for modal navigation).
 * - **trackingClasses** - string - Additional classes to be added to the element - for tracking purposes.
 * - **color** - AnchorBaseColors - The button color, optional.
 */
export const NavButton = (props: NavButtonProps) => {
  // Destructure props
  const {
    direction,
    navigationJob,
    onClick,
    disabled,
    className,
    color,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Helpers
  const tooltipText = useMemo(
    () => `${t(direction === 'next' ? 'job.modal.next' : 'job.modal.previous')}${navigationJob ? `: ${t(navigationJob.title)}` : ''}`,
    [navigationJob, direction, t],
  );

  // Icon
  const Icon = direction === 'next' ? ChevronRightIcon : ChevronLeftIcon;

  // Render component
  // ************************************
  return (
    <Tooltip
      tooltipText={tooltipText}
      position={navigationJob ? 'top' : 'bottom'}
      colorScheme="black"
      contentSizeClass={clsx({ '!min-w-40': !!navigationJob })}
      forceHideTooltip={disabled}
    >
      {navigationJob ? (
        <Anchor
          href={navigationJob?.url}
          color={color}
          size="sm"
          rounding="full"
          className={className}
        >
          <Icon className="size-5" />
        </Anchor>
      ) : (
        <Button
          color={color}
          size="sm"
          rounding="full"
          disabled={disabled}
          className={className}
          onClick={onClick}
        >
          <Icon className="size-5" />
        </Button>
      )}
    </Tooltip>
  );
};
