/** Transactions */
export const TRANSACTIONS_ROUTE = '/wallet/transactions';
export const TRANSACTIONS_ROUTE_CANDIDATE = '/wallet/transactions';
export const TRANSACTIONS_TAG = 'wallet-transactions';
export const TRANSACTIONS_BALLANCE_TAG = 'wallet-balance-by-expiration-date';

/** Orders */
export const ORDERS_ROUTE = '/wallet/orders';
export const ORDERS_TAG = 'wallet-orders';
export const USER_ORDERS_TAG = 'wallet-user-orders';

/** Billing data */
export const BILLING_DATA_ROUTE = '/wallet/billing-data';
export const BILLING_DATA_TAG = 'wallet-billing-data';
