// Evaluate cv from job detail **************************************************************************
// pathname: /v1/resume/evaluate/${jobSlug
// method:   POST
// ************************************************************************************************
import { GenericV1ErrorsArray, useSwrMutationApi } from '@hooks/useSwrApi';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';

/**
 * @description SWR mutation: POST to get ai evaluation for profile. Response status ok: 200.
 * @param jobSlug - string. The job slug.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param successCallback - function. The callback to call on success.
 * @param errorCallback - function. The callback to call on error.
 */
export const useSwrJobEvaluateBia = (
  jobSlug: string,
  locale: string = 'ro',
  successCallback?: (res: ApiSchema<'ApplicationEvaluation'>) => void,
  errorCallback?: (errors: GenericV1ErrorsArray) => void,
) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, undefined>({
    url: `/v1/resume/evaluate/${jobSlug}`,
    locale,
    fetcherConfig: {
      method: 'POST',
    },
    apiOptions: {
      onSuccess: (response) => {
        sendGoogleTagEvent('job_detail_bia_evaluate', 'Custom');
        if (successCallback) successCallback(response);
      },
      onError: (errors) => {
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showSuccessToast: false,
      showErrorToast: false,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
