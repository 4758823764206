import { useTranslation } from 'next-i18next';
import { JobQuestion } from '@type/v1-api-types';
import { detailSectionsStyles } from '@components/templates/job/detail/detailSectionStyles';

// Interface
// ************************************
export interface OnlineInterviewProps {
  questions: JobQuestion[]
}

/**
 * @description Job detail - Online Interview Component.
 * - **questions** - JobQuestion[].
 */
export const OnlineInterview = (props: OnlineInterviewProps) => {
  // Destructure props
  const { questions } = props;

  // Translation
  const { t } = useTranslation('common');

  // Render component
  // ************************************
  return (
    <div className={detailSectionsStyles.common}>
      <h3 className={detailSectionsStyles.title}>
        { t('job.detail.online_interview') }
      </h3>
      <div className="mb-10 mt-4 space-y-2 rounded bg-surface-50 p-2">
        {questions.map((questionItem) => (
          <div key={questionItem.id} className="rounded bg-white p-4">
            {questionItem.question}
            {questionItem.answer && (
              <>
                <p className="mt-4 text-xs text-ink-light">
                  { t('job-detail-your-answer') }
                </p>
                {questionItem.answer.answer}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
