import { Anchor } from '@components/common';
import { ArrowRightCircleIcon, ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import { detailSectionsStyles } from '@components/templates/job/detail/detailSectionStyles';
import { useTranslation } from 'next-i18next';
import { ApiPrevNextJobOk } from '@utils/data-fetching';
import { clsx } from 'clsx';

// Interface
// ************************************
export interface NavigationProps {
  apiPrevNextJobs: ApiPrevNextJobOk;
}

/**
 * @description Job detail - Navigation for job in page (not modal)
 * - **apiPrevNextJobs** - ApiPrevNextJobOk | null.
 */
export const Navigation = (props: NavigationProps) => {
  const { apiPrevNextJobs } = props;

  // Translation
  const { t } = useTranslation('common');

  // Helper Classes
  const iconClasses = 'w-6 h-6 md:w-10 md:h-10 shrink-0 text-ink-light group-hover:text-ink-medium/80';

  // Don't render if there are not prev / next jobs
  // ************************************
  if (!apiPrevNextJobs.prevJob && !apiPrevNextJobs.nextJob) {
    return null;
  }

  // Render component
  // ************************************
  return (
    <div className={`${detailSectionsStyles.common} pb-4 lg:pb-0`}>
      <div className="grid grid-cols-2 gap-x-4 md:gap-x-6">

        {/* Next job */}
        {apiPrevNextJobs.nextJob && (
          <div className="col-start-2 row-start-1 flex justify-end">
            <Anchor
              styling="text"
              className="group hover:!no-underline"
              href={apiPrevNextJobs.nextJob.url}
            >
              {/* Icon */}
              <ArrowRightCircleIcon className={clsx(iconClasses, 'order-1 ml-1 md:ml-3')} />

              {/* Job title */}
              <div className="text-right">
                <span className="text-xs">{t('job.modal.next')}</span>
                <div className="line-clamp-2 font-bold">{t(apiPrevNextJobs.nextJob.title)}</div>
              </div>
            </Anchor>
          </div>
        )}

        {/* Prev job */}
        {apiPrevNextJobs.prevJob && (
          <div className="col-start-1 row-start-1 flex justify-start">
            <Anchor
              styling="text"
              className="group hover:!no-underline"
              href={apiPrevNextJobs.prevJob.url}
            >
              {/* Icon */}
              <ArrowLeftCircleIcon className={clsx(iconClasses, 'mr-1 md:mr-3')} />

              {/* Job title */}
              <div>
                <span className="text-xs">{t('job.modal.previous')}</span>
                <div className="line-clamp-2 font-bold">{t(apiPrevNextJobs.prevJob.title)}</div>
              </div>
            </Anchor>
          </div>
        )}

      </div>
    </div>
  );
};
