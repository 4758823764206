import { MouseEventHandler, ReactNode } from 'react';
import { Button, Anchor } from '@components/common';

// Interface
// ************************************
export interface TagProps {
  children: ReactNode;
  slug?: string;
  className?: string;
  onClick?: MouseEventHandler;
}

/**
 * @description Tag. Generic component used to render a skill or a language in job details page.
 * It renders a link or a simple button.
 * * **children** - string: the text for the tag.
 * - **slug** - string, optional: the text for the tag.
 * - **className** - string, optional: additional classes to add.
 * - **onClick** - MouseEventHandler, optional: function to be called when the tag is clicked.
 */
export const Tag = (props: TagProps) => {
  // Destructure props
  const {
    children,
    slug,
    className = '',
    onClick,
    ...restProps
  } = props;

  // Helper classes
  const tagCommonClasses = `!text-sm/4 !bg-surface-50 hover:!text-primary ${className}`;

  // Render
  return (
    slug ? (
      <Anchor
        color="light"
        rounding="full"
        size="xs"
        className={tagCommonClasses}
        href="app_job_keyword"
        symfonyRoute
        symfonyParams={{ keyword: slug }}
        {...restProps}
      >
        { children }
      </Anchor>
    ) : (
      <Button
        color="light"
        rounding="full"
        size="xs"
        className={tagCommonClasses}
        onClick={onClick}
        {...restProps}
      >
        { children }
      </Button>
    )
  );
};
