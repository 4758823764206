// SAVE ORDER *************************************************************************************
// pathname: /v1/order/save-order/${shoppingCartId}
// ************************************************************************************************
import { useSwrMutationApi } from 'src/hooks/useSwrApi';
import { ApiOrderSaveOrderPost, ApiOrderSaveOrder } from '@type/v1-api-types/order';

/**
 * @description Hook interface.
 */
interface UseSwrSaveOrderProps {
  locale: string,
  shoppingCartId?: number | string,
  successCallback?: (response: ApiOrderSaveOrder) => Promise<void> | void,
  errorCallback?: () => void,
}

/**
 * @description Hook to save order.
 * - `locale` - the Locale.
 * - `shoppingCartId` - the shopping cart id.
 * - `successCallback` - function to execute on succes.
 * - `errorCallback` - function to execute on error.
 */
export const useSwrSaveOrder = (props: UseSwrSaveOrderProps) => {
  // Destructure props
  const {
    locale,
    shoppingCartId,
    successCallback,
    errorCallback,
  } = props;

  // SWR: fetch data
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiOrderSaveOrder, ApiOrderSaveOrderPost>({
    url: `/v1/order/save-order/${shoppingCartId ?? ''}`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: (response) => {
        if (successCallback) { void successCallback(response); }
      },
      onError: () => {
        if (errorCallback) errorCallback();
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
