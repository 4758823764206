import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { Button, Anchor, Tooltip } from '@components/common';
import { cn } from '@utils/cn';

// Interface
// ************************************
export interface JobLocationsProps {
  locations?: ApiSchema<'JobLocationRead'>[];
  partialRemote?: boolean;
  remote?: boolean;
  asLinks?: boolean;
  withTooltip?: boolean;
  displayIcon?: boolean;
  maxDisplayedLocations?: number;
  emphasizeRemote?: boolean;
  className?: string;
  iconClassName?: string;
}

const composeLocationItemClasses = (index: number, maxDisplayed: number, showAll: boolean) => {
  if (index < maxDisplayed) {
    return '';
  }

  return !showAll ? 'hidden' : 'animate-appear';
};

/**
 * @description Job Locations component. Renders locations for a job and also displays info if the job is remote or partial remote.
 * We can set a limit of maximum displayed locations. Note that the locations are only hidden from the UI, they need be rendered in DOM for SEO purposes.
 *
 * - **locations** - JobLocation[] - The locations for the current job.
 * - **partialRemote** - boolean - Whether the job is partial remote.
 * - **remote** - boolean - Whether the job is full remote. If true, there are no locations.
 * - **asLinks** - boolean. Whether to display as links or not.
 * - **withTooltip** - boolean. Whether to display the tooltip or not.
 * - **displayIcon** - boolean. Whether to display the MapPin icon at the beginning of the component.
 * - **maxDisplayedLocations** - number. Maximum displayed locations.
 * - **emphasizeRemote** - boolean. Whether to emphasize Remote labels or not.
 * - **className** - string. Additional classnames to add to the parent div.
 * - **iconClassName** - string. Additional classnames to add to the icon.
 */
export const JobLocations = (props: JobLocationsProps) => {
  // Destructure props
  const {
    locations,
    partialRemote,
    remote,
    asLinks = true,
    withTooltip = false,
    displayIcon = true,
    maxDisplayedLocations = Infinity,
    emphasizeRemote = true,
    className = '',
    iconClassName = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Show all locations state
  const [showAll, setShowAll] = useState<boolean>(false);

  // When locations are updated (eg - a new job is displayed), reset the show all
  useEffect(() => {
    setShowAll(false);
  }, [locations]);

  // Return null if locations are empty and job is not remote
  if (!locations?.length && !remote) {
    return null;
  }

  // Helper variables
  // ************************************
  const showMoreButton = !!locations?.length && locations.length > maxDisplayedLocations && !showAll;

  // Render location name
  // ************************************
  const renderLocationName = (loc: ApiSchema<'JobLocationRead'>) => {
    if (asLinks) {
      return (
        <Anchor
          styling="none"
          href="app_job_canonical_city"
          symfonyRoute
          symfonyParams={{ location: loc.slug }}
          className="hover:text-ink"
        >
          {loc.name}
        </Anchor>
      );
    }
    return loc.name;
  };

  // Render component
  // ************************************
  return (
    <div className={cn('items-top flex', className)}>
      {displayIcon && <MapPinIcon className={`mr-2 mt-px size-4 shrink-0 ${iconClassName}`} />}
      <div className="flex-1">
        {/* Locations */}
        {!!locations?.length && locations.map((location, index) => (
          <span className={composeLocationItemClasses(index, maxDisplayedLocations, showAll)} key={location.slug}>
            {withTooltip && location.tooltip ? (
              <Tooltip tooltipText={location.tooltip} colorScheme="black" position="top" floating>
                {renderLocationName(location)}
              </Tooltip>
            ) : renderLocationName(location)}
            {index < locations.length - 1 && (index < maxDisplayedLocations || showAll) && ', '}
          </span>
        ))}

        {/* Show all locations */
          showMoreButton && (
            <Button type="button" styling="none" className="hover:text-ink" onClick={() => setShowAll(true)}>
              ...
            </Button>
          )
        }

        {/* Partial remote */
          partialRemote && (
            <>
              {!showMoreButton && ';'}
              <span className={emphasizeRemote ? 'font-bold' : ''}>
                {` ${t('job.add.label.partial.remote')}`}
              </span>
            </>
          )
        }

        {/* Remote */
          remote && (
            <span className={emphasizeRemote ? 'font-bold' : ''}>{t('job.add.label.remote')}</span>
          )
        }
      </div>
    </div>
  );
};
