import { useTranslation } from 'next-i18next';
import {
  AnimatedCollapsible,
  Avatar,
  BadgePill,
  Button,
  RatingScore,
  SanitizeHTML,
  Tooltip,
  ImageBj,
  ImageGallery,
  LinkStyled,
} from '@components/common';
import { CommentAltChecked, MedalSolidIcon } from '@components/common/Icons';
import { detailSectionsStyles } from '@components/templates/job/detail/detailSectionStyles';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import 'styles/bj_theme/bj_slick_image_gallery.css';
import { ImageGalleryItem } from '@type/image-gallery-types';

// Interface
// ************************************
export interface CompanyWidgetProps {
  employer?: ApiSchema<'JobEmployerRead'>,
  workplace?: ApiSchema<'JobWorkplaceRead'>
}

/**
 * @description Job Detail Company Widget.
 * - **employer** - JobEmployer
 */
export const CompanyWidget = (props: CompanyWidgetProps) => {
  // Destructure props
  const { employer, workplace } = props;

  // Translation
  const { t } = useTranslation('common');

  // Conditions for showing badges
  // *****************************************
  const showBadge = {
    premium: workplace ? workplace.premium : employer?.premium,
    responsive: workplace
      ? (!workplace?.premium && workplace?.responsive)
      : (!employer?.premium && employer?.responsive),
  };

  // Helper classes
  // *****************************************
  const badgeIconClass = 'w-3 h-3 fill-current';

  // Description & Images
  const description = workplace ? workplace.description : employer?.description;
  const images = workplace ? workplace.images : employer?.images;
  const companyName = workplace ? workplace.name : employer?.employerName;

  // Render component
  // ************************************
  return (
    <div className={detailSectionsStyles.common} id="company-widget-box">
      <div className="rounded border p-6">
        <div className="flex flex-col items-center lg:flex-row">
          {workplace && (
            <>
              <LinkStyled
                href={`/company-profile/${workplace.slug}`}
                styling="none"
                className="mb-4 lg:order-1 lg:mb-0"
              >
                <Avatar
                  imgPath={workplace.logoUrl}
                  imgFolder="employer_logo"
                  extractImageName
                  name={workplace.name}
                  size="md"
                  aspectRatio="landscape"
                  id={(workplace.name ?? t('employer.name.confidential')).charCodeAt(0)}
                />
              </LinkStyled>
              <div className="flex-1 lg:mr-4">
                <h3 className="text-center text-base/6 font-bold text-primary lg:text-left">
                  <LinkStyled
                    href={`/company-profile/${workplace.slug}`}
                    styling="none"
                    className="mb-4 lg:order-1 lg:mb-0"
                  >
                    {workplace.name}
                  </LinkStyled>
                </h3>
                {workplace?.showRating && workplace?.rating && (
                  <div className="mt-1">
                    <RatingScore score={workplace.rating} showScore className="size-4 text-warning" />
                  </div>
                )}
              </div>
            </>
          )}
          {(!workplace) && (
            <>
              <div className="mb-4 lg:order-1 lg:mb-0">
                <Avatar
                  imgPath={employer?.employerLogo}
                  imgFolder="employer_logo"
                  extractImageName
                  name={employer?.employerName ?? t('employer.name.confidential')}
                  size="md"
                  aspectRatio="landscape"
                  id={(employer?.employerName ?? t('employer.name.confidential')).charCodeAt(0)}
                />
              </div>
              <div className="flex-1 lg:mr-4">
                <h3 className="text-center text-base/6 font-bold lg:text-left">
                  {employer?.employerName ?? t('employer.name.confidential')}
                </h3>
                {employer?.showRating && employer?.rating && (
                  <div className="mt-1">
                    <RatingScore score={employer.rating} showScore className="size-4 text-warning" />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {/* Premium / responsive badges */}
        {(showBadge.premium || showBadge.responsive) && (
          <div className="mt-4 flex justify-center lg:justify-start">
            {showBadge.premium && (
              <BadgePill color="primary" styling="soft">
                <MedalSolidIcon className="size-3 fill-current" />
                {t('premium_talent_search_text')}
              </BadgePill>
            )}
            {showBadge.responsive && (
              <Tooltip
                tooltipText={t('responsive.employer.badge.description')}
                colorScheme="black"
                position="top"
              >
                <BadgePill color="secondary" styling="soft">
                  <CommentAltChecked className={badgeIconClass} />
                  {t('employer.card.responsive')}
                </BadgePill>
              </Tooltip>
            )}
          </div>
        )}

        {/* Company description - with animated show more based on height */
          description && (
            <div className="mt-4 lg:mt-6">
              <AnimatedCollapsible
                collapsedHeight={72}
                renderContent={() => (
                  <SanitizeHTML
                    as="div"
                    htmlContent={description}
                    config={{ ADD_TAGS: ['iframe'] }}
                    className="prose"
                  />
                )}
                renderToggle={(isExpanded, handleToggle) => (
                  <Button
                    type="button"
                    styling="none"
                    className="mt-1 flex h-10 w-full items-center justify-center rounded-full text-sm font-medium text-primary outline-none transition-all duration-200 hover:bg-surface-100 hover:shadow"
                    onClick={handleToggle}
                  >
                    {isExpanded ? (
                      <>
                        <ChevronUpIcon className="mr-1 size-5 shrink-0" />
                        {t('global.less.about.company-name.react', { company_name: companyName })}
                      </>
                    ) : (
                      <>
                        <ChevronDownIcon className="mr-1 size-5 shrink-0" />
                        {t('global.more.about.company-name.react', { company_name: companyName })}
                      </>
                    )}
                  </Button>
                )}
              />
            </div>
          )
        }

        {/* Company images */}
        {!!images?.length && (
          <ImageGallery
            items={images.map((image, index): ImageGalleryItem => ({
              src: image.imageSrc,
              thumbnail: image.thumbSrc,
              alt: `${companyName}${index + 1}`,
            }))}
            renderItems={(handleItemClick) => (
              <div className="mt-6 grid grid-cols-3 gap-4 sm:grid-cols-4 xl:grid-cols-6">
                {images?.map((image, index) => (
                  <a
                    key={image.thumbSrc}
                    href={image.imageSrc}
                    className="relative aspect-[4/3] w-full"
                    onClick={(e) => { e.preventDefault(); handleItemClick(index); }}
                  >
                    {image.thumbSrc && (
                      <ImageBj
                        src={image.thumbSrc}
                        alt={`${companyName}${index + 1}`}
                        className="rounded-md object-cover"
                        fill
                      />
                    )}
                  </a>
                ))}
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
};
