import { useTranslation } from 'next-i18next';
import { JobSpokenLanguage } from '@type/v1-api-types';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Tag } from '@components/templates/job/detail/partials';
import { toSeparatorCase } from '@utils/strings/convertCase';
import { detailSectionsStyles } from '../detailSectionStyles';

// Interface
// ************************************
export interface TagsProps {
  spokenLanguages?: JobSpokenLanguage[];
}

/**
 * @description Job Detail Tags. Renders Esko Skills and/or Languages for a job with a View more button.
 * - **locale** - Locale - The current app locale.
 * - **spokenLanguages** - JobSpokenLanguage[]
 */
export const Tags = (props: TagsProps) => {
  // Destructure props
  const {
    spokenLanguages,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Return null if there are no skills or languages
  // ************************************
  if (!spokenLanguages?.length) return null;

  // Render component
  // ************************************
  return (
    <div className="pt-8">
      <h3 className={detailSectionsStyles.title}>
        { t('cv-completion-card-languages-title') }
      </h3>

      <div className="mt-5 flex flex-wrap gap-2">
        {/* Spoken Languages */
          !!spokenLanguages?.length && spokenLanguages.map((language) => (
            <Tag
              key={language.shortName}
              slug={toSeparatorCase(language.name)}
            >
              {language.matched && (
                <CheckIcon className="mr-1.5 size-4 text-secondary" />
              )}
              {`${language.name} (${language.levelName.toLowerCase()})`}
            </Tag>
          ))
        }

      </div>
    </div>
  );
};
