import { Fragment } from 'react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import { Anchor } from '@components/common';
import { JobCareerLevel } from '@type/v1-api-types';

// Interface
// ************************************
export interface JobCareerLevelsProps {
  careerLevels?: JobCareerLevel[];
  asLinks?: boolean;
  displayIcon?: boolean;
  className?: string;
  iconClassName?: string;
}

/**
 * @description Job Career Levels component. Renders career levels for a job.
 * - **careerLevels** - JobCareerLevel[] - An array with career levels.
 * - **asLinks** - boolean. Whether to display as links or not.
 * - **displayIcon** - boolean. Whether to display the BriefcaseIcon icon at the beginning of the component.
 * - **className** - string. Additional classnames to add to the parent div.
 * - **iconClassName** - string. Additional classnames to add to the icon.
 */
export const JobCareerLevels = (props: JobCareerLevelsProps) => {
  // Destructure props
  const {
    careerLevels,
    asLinks = true,
    displayIcon = true,
    className = '',
    iconClassName = '',
  } = props;

  // Return null if career levels are empty
  if (!careerLevels?.length) {
    return null;
  }

  // Render component
  // ************************************
  return (
    <div className={`flex ${className}`}>
      {displayIcon && <BriefcaseIcon className={`mt-px size-4 shrink-0 ${iconClassName}`} />}
      <div className="ml-2">
        {careerLevels.map((careerLevel, index) => (
          <Fragment key={careerLevel.slug}>
            {asLinks ? (
              <Anchor
                styling="none"
                href="app_job_canonical"
                symfonyRoute
                symfonyParams={{ params: careerLevel.slug }}
                className="hover:text-ink"
              >
                {careerLevel.name}
              </Anchor>
            ) : (
              careerLevel.name
            )}
            {index < careerLevels.length - 1 && ', '}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
