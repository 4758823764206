/**
 * @description Job Detail Company Short Placeholder.
 */
export const CompanyShortPlaceholder = () => (
  <div className="animate-pulse mt-11 w-64 flex items-center">
    <div className="rounded-full bg-slate-400 w-15 h-15 mr-4" />
    <div className="space-y-4 flex-1">
      <div className="bg-slate-400 h-3 w-full rounded" />
      <div className="bg-slate-400 h-2 w-full rounded" />
    </div>
  </div>
);
