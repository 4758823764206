import { TRANSACTIONS_ROUTE_CANDIDATE } from '@lib/actions/api/wallet/tags';
import { Dispatch, SetStateAction } from 'react';
import { ApiOrderSaveOrder } from '@type/v1-api-types/order';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import {
  useSwrOrderContinueCandidate,
  useSwrCreateOrderCandidate,
} from '@hooks/useSwrApi/endpoints/order';
import { useSwrGlobal } from '@hooks/useSwrApi';

/**
 * @description Hook interface.
 */
interface UseBuyNeuroProps {
  stepSetter?: Dispatch<SetStateAction<string>>,
  successCallbackFunction?: () => void,
}


/**
 * @description Hook to buy Neuro.
 * - `stepSetter` - function to set the flow step.
 * - `externalCartId` - the external cart id. If you use the hook in a different context (ie: a page with id as a param),
 * you can provide the cart id here.
 */
export const useBuyNeuroCandidate = (props?: UseBuyNeuroProps) => {
  // Destructure props
  const { stepSetter, successCallbackFunction } = props || {};

  // Router
  const { locale, pathname, router } = useExtendedRouter();

  // SWR Global
  const { revalidateKey } = useSwrGlobal();

  // Handle the order error
  const handleOrderError = () => {
    if (stepSetter) stepSetter('Error');
  };

  // - If the order is completed and paid, show success step.
  // - If the order is completed but not paid (payment was by invoice), show success step + link to invoice.
  // - If the order is not completed, show failed step.
  // ****************************************************
  const handleOrderSuccess = async (data: ApiOrderSaveOrder) => {
    let step: string | undefined;
    // Check the order status
    if (data?.completed) {
      step = data?.paid ? 'CompletedCard' : 'CompletedInvoice';
      // Refresh the transactions page if we are on it. Give the backend time to process the order
      if (pathname && pathname.startsWith(TRANSACTIONS_ROUTE_CANDIDATE)) router.refresh();
      // Callback like refetch job detail in case of automated charge
      if (successCallbackFunction) {
        setTimeout(() => {
          void successCallbackFunction();
        }, 5000);
      }
      setTimeout(() => revalidateKey('header-info'), 5000);
    } else if (data?.checkoutSessionUrl) {
      window.location.replace(data.checkoutSessionUrl);
      return;
    } else {
      step = 'NotCompleted';
    }
    // Set the step
    if (stepSetter) stepSetter(step);
  };

  // SWR: Get shopping cart details
  // This is super simple - will auto-trigger when the shopping cart id is available, no action needed
  const {
    data: cartDetails,
    isLoading: loadingCartDetails,
  } = useSwrOrderContinueCandidate({
    locale,
  });

  // SWR: Complete order
  const {
    trigger: orderCredits,
    data: orderResponse,
    isMutating: savingOrder,
  } = useSwrCreateOrderCandidate({
    locale,
    successCallback: handleOrderSuccess,
    errorCallback: handleOrderError,
  });

  // ****************************************************
  // Return
  // ****************************************************
  return {
    cartDetails,
    loadingCartDetails,
    orderCredits,
    orderResponse,
    savingOrder,
    paths: {
      toInvoice: orderResponse && orderResponse?.completed && orderResponse?.paid === false && orderResponse?.orderId
        ? () => router.push(`/wallet/order/${orderResponse.orderId}`)
        : undefined,
      notCompleted: orderResponse && orderResponse?.completed === false && orderResponse?.orderId
        ? () => router.push(`/order/checkout/${orderResponse.orderId}`)
        : undefined,
    },
  };
};
