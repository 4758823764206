// Company Landing ********************************************************************************
// pathname: /v1/company/landing
// ************************************************************************************************
import {
  useSwrMutationApi, ShoppingOfferSavedOffer,
} from 'src/hooks/useSwrApi';
import { useTranslation } from 'next-i18next';


/**
 * @description SWR: fetch company landing page data.
 * @param locale - { string } - the locale to use for the request. Default: 'ro'.
 * @param postData - { object } - the data we want to send to the server. Default: undefined.
 * @param urlAddon - { string } - the url addon to use for the request. Default: undefined.
 */
export const useSwrShoppingOfferSave = (locale: string, urlAddon?: string) => {
  // Get data from API
  // ********************************************
  const { t } = useTranslation('common');

  const {
    data, error, trigger, isMutating, reset,
  } = useSwrMutationApi<ShoppingOfferSavedOffer, object >({
    url: `/v1/order/save-shopping-offer${urlAddon ? `/${urlAddon}` : ''}`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    toastsOptions: {
      showErrorToast: true,
      showSuccessToast: true,
      successToastText: t('offer-configurator.save.success'),
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    trigger,
    isMutating,
    reset,
  };
};
