import { useTranslation } from 'next-i18next';
import { Modal } from '@components/common/Modal/Modal';
import { UserBuyNeuroHub } from './UserBuyNeuroHub';

interface CandidateOrderProps {
  isOpen: boolean,
  toggleModal: () => void,
  jobSlug?: string,
  successCallback?:()=>void
}

/**
 * @description Renders the buy neuro modal for candidates, with payment options, pricing and if
 * it's the case the 'not enough credits' and payment status possible steps.
 * The modal can be opened anywhere in the app using the **showBuyNeuroCandidateModal** function.
 */
export const BuyNeuroCandidateModal = (props: CandidateOrderProps) => {
  const {
    isOpen, toggleModal, jobSlug, successCallback,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      size="md"
      header={t('global.action.order')}
      body={<UserBuyNeuroHub closeModal={toggleModal} jobSlug={jobSlug} successCallback={successCallback} />}
    />
  );
};
