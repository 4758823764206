// Company Landing ********************************************************************************
// pathname: /v1/company/landing
// ************************************************************************************************
import {
  useSwrApi, ApiShoppingOffer, RequestedProduct,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR: fetch company landing page data.
 * @param locale - { string } - the locale to use for the request. Default: 'ro'.
 * @param postData - { object } - the data we want to send to the server. Default: undefined.
 * @param serverSideData - { ApiShoppingOffer } - the data we got from the server side request (if we do it). Default: undefined.
 * @param urlAddon - { string } - the url addon to use for the request. Default: undefined.
 */
export const useSwrShoppingOffer = (
  locale: string,
  postData: RequestedProduct[],
  serverSideData?: ApiShoppingOffer,
  urlAddon?: string,
) => {
  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiShoppingOffer>({
    url: `/v1/order/shopping-offer${urlAddon ? `/${urlAddon}` : ''}`,
    locale,
    serverSideData,
    fetcherConfig: {
      method: 'POST',
      payload: postData.length > 0 ? { products: postData } : undefined,
      stringifyPayload: true,
    },
    apiOptions: {
      revalidateOnFocus: false,
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
