// RESUME SKILLS SUGGEST  *************************************************************************
// pathname: /v1/resume/skill/suggest
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { IGenericApiStrings } from '@type/generic-api-strings';


// SWR: fetch resume skills suggest
// Usage: const { data: skills, ... } = useSwrResumeSkillsSuggest(locale, page);
// ********************************************
export const useSwrResumeSkillsSuggest = (locale: string, page = 1) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<IGenericApiStrings[]>({
    url: `/v1/resume/skill/suggest?page=${page}`,
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60, // 1 minute
    },
  });

  // Return data
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
