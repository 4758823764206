import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { useBjForm } from '@hooks/useBjForm';
import { useTranslation } from 'next-i18next';
import { CardPaymentList } from '@components/features/payment';
import {
  Input, Button, Label, RadioText, FormError,
} from '@components/common/Forms';
import { PhoneInputStandalone } from '@components/common/Forms/PhoneInputStandalone/PhoneInputStandalone';
import { useRouterLocale } from '@hooks/common/useRouterLocale';
import { PencilIcon } from '@heroicons/react/24/solid';
import { clsx } from 'clsx';
import { TailwindSpinner } from '@components/common';
import { useCreditsCalculator } from '@hooks/credits/useCreditsCalculator';

/**
 * @description Payment methods interface.
 */
interface PaymentMethodsProps {
  cartDetails?: ApiSchema<'UserOrderPayment'>,
  orderCredits: <Data, Args>(arg: Args) => Promise<Data | void>,
  savingOrder?: boolean,
  stepBack?: () => void,
  transitionProps?: object,
  jobSlug?: string,
}

/**
 * @description Payment methods component.
 * - `cartDetails` - the cart details.
 * - `orderCredits` - function to call when the user clicks the submit button.
 * - `savingOrder` - boolean to show loading state on the submit button.
 * - `deleteShoppingCart` - function to call when the user clicks the delete order button.
 * - `stepBack` - function to call when the user clicks the edit order button.
 * - `transitionProps` - Common transition props for the Transition component. If not provided, there will be no transition.
 */
export const PaymentMethodsCandidate = (props: PaymentMethodsProps) => {
  // Destructure props
  const {
    cartDetails,
    orderCredits,
    savingOrder,
    transitionProps,
    jobSlug,
  } = props;

  const locale = useRouterLocale();

  // Translation
  const { t } = useTranslation('common');

  // Form hook
  const { record, handleCallbackSubmit } = useBjForm();

  // States for managing the selected payment method
  const [selectedMethod, setSelectedMethod] = useState(cartDetails?.paymentTypes?.[0]);
  const [selectedCard, setSelectedCard] = useState(Number(cartDetails?.creditCards?.find(((card) => card.lastActive))?.id) || 0);
  const [phoneConfirmed, setPhoneConfirmed] = useState<boolean>(true);

  const minimumCreditsAmount = 10;

  // Credit calculator hook
  const {
    maxValue,
    isValid,
    creditsAmount,
    getCreditsAmount,
    priceIsValidating,
  } = useCreditsCalculator(
    minimumCreditsAmount,
    10,
  );

  const isFormDefaultHidden = (
    (cartDetails?.lastName && cartDetails.lastName !== '')
    && (cartDetails?.firstName && cartDetails.firstName !== '')
    && (cartDetails?.address && cartDetails.address !== '')
    && (cartDetails.email && cartDetails.email !== '')
  ) as boolean;

  const [isFormClosed, setIsFormClosed] = useState<boolean>(isFormDefaultHidden);
  const classes = {
    label: 'font-bold text-ink-medium ml-3',
    parent: '!mt-0 mb-4',
  };

  // Render component
  // ********************************
  return (
    <form
      noValidate
      onSubmit={(event) => handleCallbackSubmit(event, orderCredits)}
    >
      {/* Form content */}
      <Transition className="px-6 pt-3" {...transitionProps}>
        <div className={clsx(
          'flex justify-between bg-surface-100 p-4',
          !isFormClosed && '!hidden',
        )}
        >
          <div>
            <span className="flex font-bold">
              {`${cartDetails?.firstName} ${cartDetails?.lastName}`}
            </span>
            <span className="flex">
              {cartDetails?.address}
            </span>
            <span className="flex">
              {cartDetails?.email}
            </span>
          </div>
          <div>
            <Button styling="text" color="ink-medium" onClick={() => setIsFormClosed(false)}>
              <PencilIcon className="size-6" />
            </Button>
          </div>
        </div>

        <div className={clsx(
          'md:grid md:grid-cols-2 md:gap-x-3',
          isFormClosed && '!hidden',
        )}
        >
          {/* Form data */}
          <div className={classes.parent}>
            <Label
              htmlFor="lastName"
              descriptionClass={classes.label}
              description={t('order.billing.lastName')}
              required
            >
              <Input
                defaultValue={cartDetails?.lastName || ''}
                type="text"
                {...record('lastName', {})}
                required
              />
            </Label>
          </div>
          <div className={classes.parent}>
            <Label
              htmlFor="firstName"
              descriptionClass={classes.label}
              description={t('order.billing.firstName')}
              required
            >
              <Input
                defaultValue={cartDetails?.firstName || ''}
                type="text"
                {...record('firstName', {})}
                required
              />
            </Label>
          </div>
          <div className={classes.parent}>
            <Label
              htmlFor="address"
              descriptionClass={classes.label}
              description={t('order.billing.address')}
              required
            >
              <Input
                defaultValue={cartDetails?.address || ''}
                type="text"
                {...record('address', {})}
                required
              />
            </Label>
          </div>
          <div className={classes.parent}>
            <Label
              htmlFor="email"
              descriptionClass={classes.label}
              description={t('order.billing.email')}
              required
            >
              <Input
                defaultValue={cartDetails?.email || ''}
                type="text"
                {...record('email', {})}
                required
              />
            </Label>
          </div>
        </div>

        <div className={clsx(
          classes.parent,
          'hidden md:w-1/2 md:pr-3',
          !phoneConfirmed && '!block',
        )}
        >
          <PhoneInputStandalone
            toGetDefaultUserPhoneFromApi
            record={record}
            recordName="phone"
            locale={locale}
            phoneConfirmedStatusCallback={setPhoneConfirmed}
          />
        </div>

        <Input
          type="hidden"
          value={selectedCard}
          {...record('creditCardId', { convertEmptyValue: 'toNumber' })}
        />

        <Input
          type="hidden"
          value={selectedMethod?.id}
          {...record('paymentTypeId')}
        />

        {jobSlug && (
          <Input
            type="hidden"
            value={jobSlug}
            {...record('jobSlug')}
          />
        )}
        <div className="mt-4 border-t pt-4">
          <CardPaymentList
            paymentMethods={cartDetails?.paymentTypes}
            creditsAmount={creditsAmount}
            creditCards={cartDetails?.creditCards}
            getSelectedMethod={setSelectedMethod}
            getSelectedCard={setSelectedCard}
            showAvailableCredits={false}
            className="max-w-sm md:!grid-cols-1"
          />
        </div>
        {/* Payment methods */}

        { selectedCard === 0 && (
          <div className="mt-3">
            <Label htmlFor="saveCard" className="flex">
              <Input type="checkbox" {...record('saveCard', { convertValue: 'toBoolean', defaultChecked: true })} />
              <RadioText>{t('payment.save.card.label')}</RadioText>
            </Label>
          </div>
        )}

        {/* Order details */}
        <div className="mt-3 border-y px-4 py-3">
          {/* Neuro amount */}
          <div className="mb-3 flex items-center justify-between">
            <div>
              <span>{t('neuro.label.simple.amount')}</span>
              <p className="text-sm text-ink-medium">
                {!isValid ? (
                  <FormError
                    errorMessage={t('error.message.not_in_range', { min: 10, max: maxValue })}
                    isShowing={!isValid}
                  />
                )
                  : t('neuro.buy.candidate.min.quantity')}
              </p>
            </div>
            <Input
              className="w-20 text-right"
              type="number"
              min={minimumCreditsAmount}
              max={maxValue}
              onChange={getCreditsAmount}
              autoComplete="off"
              {...record('amount', { convertEmptyValue: 'toNumber', defaultValue: minimumCreditsAmount })}
            />
          </div>

          {/* Package price in euros; Free trial data is first in pecking order */}
          <div className="flex items-center justify-between py-1">
            <span>{t('total.with.vat')}</span>
            <strong className="text-right text-xl text-secondary">
              { // If loading, show spinner, else show amount or 0
                priceIsValidating
                  ? <TailwindSpinner className="size-4 text-ink-medium" />
                  : <>{!isValid ? 0 : creditsAmount}</>
              }
              <span className="ml-1 text-sm">€</span>
            </strong>
          </div>
        </div>
      </Transition>

      {/* Footer */}
      <div className="justify-center bg-surface px-6 pt-4 md:flex">
        {/* Submit button */}
        <Button
          type="submit"
          rounding="full"
          color="secondary"
          size="sm"
          className="mb-4 w-full md:order-2 md:w-1/2"
          isLoading={savingOrder}
          disabled={!selectedMethod || !phoneConfirmed || !isValid}
        >
          {t('neuro.buy.candidates.cta')}
        </Button>
      </div>
    </form>
  );
};
