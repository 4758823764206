import { detailSectionsStyles } from '@components/templates/job/detail';

/**
 * @description Job Detail Company Widget Placeholder.
 */
export const CompanyWidgetPlaceholder = () => (
  <div className={detailSectionsStyles.common}>
    <div className="animate-pulse bg-slate-200 h-18 w-full rounded" />
  </div>
);
