'use client';

import { ChangeEvent, useState } from 'react';
import { useDebounce } from '@hooks/useDebounce/useDebounce';
import { useSwrOrderCalculatePrice, useSwrOrderCreateCart } from '@hooks/useSwrApi/endpoints/order';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';

/**
 * @description Custom hook: credits calculator. It will automatically fetch the pricing data when the input value
 * (credits amount) changes and is a valid value between min and max. It will show a toast if there is an error.
 *
 * Returned data:
 * - **minValue**: number - the minimum value for the input.
 * - **maxValue**: number - the maximum value for the input.
 * - **isValid**: boolean - true if the input value is valid (between min and max). Keep in mind that as long as the input
 * is not required, the value can be empty, and it will be considered valid.
 * - **creditsAmount**: number - the current value of the input.
 * - **priceData**: ApiOrderCalculatePriceOk | undefined - the pricing data from server.
 * - **priceIsValidating**: boolean - true if the pricing data is being fetched. Use it to show a loader.
 * - **orderData**: ApiOrderCreateCartOk | undefined - the cart id from server.
 * - **orderIsMutating**: boolean - true if the cart id is being fetched. Use it to show a loader.
 * - **userIsLoggedIn**: boolean - true if the user is logged in.
 * - **getCreditsAmount**: (event: ChangeEvent<HTMLInputElement>) => void - debounced function to handle the input change.
 * - **handleCreditsOrder**: () => void - the function to handle the credits order.
 *
 * @param inputValue number - the initial value for the input. Default 0.
 *
 * @example
 * // second parameter is optional, no need to pass it if you don't have an initial value (ie: useCreditsCalculator(locale, 300))
 * const {
 *   priceData, priceIsValidating, orderIsMutating, getCreditsAmount, handleCreditsOrder,
 * } = useCreditsCalculator(locale);
 *
 * // use the data
 * const price = priceData?.price;
 *
 * // use the functions
 * <Input
 *   type="number"
 *   min={minValue}
 *   max={maxValue}
 *   onChange={getCreditsAmount}
 *   {...record('creditAmount', { convertEmptyValue: 'toNumber' })}
 * />
 * <FormError
 *   errorMessage={t('error.message.not_in_range', { min: minValue, max: maxValue })}
 *   isShowing={!isValid}
 * />
 * <Button onClick={handleCreditsOrder} ... />
 */
export const useCreditsCalculator = (inputValue?: number, minVal?: number) => {
  // 0. Variables. Use them to change the min and max values on the input field.
  // ********************************
  const minValue = minVal || 1;
  const maxValue = 200000;

  // Router
  const { locale } = useExtendedRouter();

  // 1. Input value state & validity
  // ********************************
  const [creditsAmount, setCreditsAmount] = useState<number>(inputValue ?? 0);
  const [isValid, setIsValid] = useState<boolean>(true);


  // 2. SWR: Price calculation. It will automatically fetch the data when the state creditsAmount changes.
  // We cache the data for 12 hours (dedupingInterval + postData key) so we don't fetch the same data multiple times.
  // ********************************
  // The condition takes in consideration the minValue and maxValue variables (min and max on input number).
  const fetchCondition = creditsAmount >= minValue && creditsAmount <= maxValue;

  const {
    data: priceData,
    isValidating: priceIsValidating,
  } = useSwrOrderCalculatePrice({
    locale,
    postData: { creditAmount: creditsAmount },
    fetchCondition,
  });


  // 3. SWR: Create cart order.
  // ********************************
  const {
    data: orderData,
    trigger: orderTrigger,
    isMutating: orderIsMutating,
  } = useSwrOrderCreateCart({
    locale,
    successRedirectPath: '/continue-order',
    addIdToSuccessRedirect: true,
  });


  // 4. Functions
  // ********************************
  // Debounce the setCreditsAmount & setIsInvalid functions
  const setDebouncedValue = useDebounce(setCreditsAmount, 500);
  const setDebouncedIsValid = useDebounce(setIsValid, 500);

  // Handle input change (debounced) and get the credits amount
  // Also check validity (if the number is between min and max)
  // use it in the input onChange event: <input onChange={getCreditsAmount} ... />
  const getCreditsAmount = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setDebouncedValue(Number(newValue));
    setDebouncedIsValid(event.target.validity.valid);
  };

  // Handle credits order (trigger the cart creation)
  // use it in the button onClick event: <button onClick={handleCreditsOrder} ... />
  const handleCreditsOrder = () => {
    if (creditsAmount > minValue && creditsAmount <= maxValue) {
      void orderTrigger({ creditAmount: creditsAmount });
    }
  };


  // Return data
  // ********************************
  return {
    minValue,
    maxValue,
    isValid,
    creditsAmount,
    priceData,
    priceIsValidating,
    orderData,
    orderTrigger,
    orderIsMutating,
    getCreditsAmount,
    handleCreditsOrder,
  };
};
