import { useTranslation } from 'next-i18next';
import {
  Modal, Button, ImageBj, SanitizeHTML,
} from '@components/common';
import estimatedSalaryInfoImg from '@images/job/img-modal-salary.svg';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ApiJobDetailOk } from '@type/v1-api-types';
import { useMemo } from 'react';
import { JobLocations } from '@components/templates/job/detail/partials/JobLocations';
import { JobCareerLevels } from '@components/templates/job/detail/partials/JobCareerLevels';

// Interface
// ************************************
export interface EstimatedSalaryInfoModalProps {
  isOpen: boolean,
  job: ApiJobDetailOk
  onClose: () => void,
}

/**
 * @description Estimated Salary Info Modal.
 * - **job** - ApiJobDetailOk. The job detail.
 * - **isOpen** - boolean. Whether the modal is opened or not.
 * - **onClose** - boolean. Callback to execute when the modal is closed.
 */
export const EstimatedSalaryInfoModal = (props: EstimatedSalaryInfoModalProps) => {
  // Destructure props
  const {
    job,
    isOpen,
    onClose,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Helper classes
  const iconClasses = 'w-4.5 h-4.5 shrink-0 mt-px';

  // Estimated salary is a string (eg: '880 - 970 EUR/luna)',
  // so we should split it and get the range and currency in separate variables
  const [estimatedSalaryRange, estimatedSalaryCurrency] = useMemo(
    () => {
      if (!job.estimatedSalary) {
        return [];
      }

      const regex = /(^\d+\s-\s\d+)\s(.+)$/;
      const matches = regex.exec(job.estimatedSalary);
      if (!matches) {
        return [];
      }
      return [matches[1], matches[2]];
    },
    [job.estimatedSalary],
  );

  // Render component
  // ************************************
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm">
      <Modal.Header onClose={onClose}>
        <div className="text-lg text-ink">
          {t('salary.estimate.main.title')}
        </div>
      </Modal.Header>
      <Modal.Body>

        {/* Estimated salary + job info */}
        <div className="grid grid-cols-1 sm:mb-6 sm:grid-cols-2">
          <div className="sm:order-1">
            <div className="mb-2 px-12 sm:px-0">
              <ImageBj src={estimatedSalaryInfoImg as string} alt={t('salary.estimate.main.title')} />
            </div>
          </div>
          <div className="sm:order-0">

            {/* Estimated salary */
              <div className="text-lg font-bold">
                {estimatedSalaryRange && (
                <span className="text-6xl/normal tracking-wide text-secondary">
                  {estimatedSalaryRange}
                </span>
                )}
                {estimatedSalaryCurrency && (
                  <span className="block">{ estimatedSalaryCurrency }</span>
                )}
              </div>
            }

            {/* Job Info */}
            <div className="mt-6 space-y-2 text-sm text-ink-medium">

              {/* Job title */}
              <div className="flex">
                <InformationCircleIcon className={iconClasses} />
                <div className="ml-2 font-bold">
                  {job.title}
                </div>
              </div>

              {/* Job locations */}
              <JobLocations locations={job.locations} remote={job.remote} partialRemote={job.partialRemote} asLinks={false} emphasizeRemote={false} />

              {/* Career Levels */}
              <JobCareerLevels careerLevels={job.careerLevels} asLinks={false} />

            </div>
          </div>
        </div>

        {/* Main Content */}
        <SanitizeHTML
          as="div"
          htmlContent={t('salary.estimate.main.content')}
          className="prose"
        />

      </Modal.Body>
      <Modal.Footer className="flex items-center justify-center !py-5">
        <Button type="button" size="sm" styling="outline" rounding="full" onClick={onClose}>
          { t('salary.estimate.footer.close') }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
