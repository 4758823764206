'use client';

import { useTranslation } from 'next-i18next';
import { LinkStyled, Modal } from '@components/common';
import { PlusIcon } from '@heroicons/react/24/solid';

interface AiEvaluationModalProps {
  isOpen: boolean,
  toggleModal: () => void,

}
/**
 * @description Renders the not enough neuro modal
 * - **isOpen**: If true, the modal will be displayed.
 * - **toggleModal**: Function to toggle the modal.
 */
export const AiEvaluation452Modal = (props: AiEvaluationModalProps) => {
  // Props destructuring
  const {
    isOpen,
    toggleModal,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      size="md"
      header={t('global.evaluation.modal.452.title')}
      body={(
        <div className="p-5 text-center">
          <div className="mb-5">
            {t('global.evaluation.modal.452.description')}
          </div>
          <LinkStyled target="_blank" href="/profile" size="sm" styling="solid" color="secondary" rounding="full">
            <PlusIcon className="mr-2 size-5" />
            {t('global.evaluation.modal.452.button.title')}
          </LinkStyled>
        </div>
      )}
    />
  );
};
