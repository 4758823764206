// Add skill to resume ****************************************************************************
// pathname: /v1/resume/skill/add/{skillId}
// ************************************************************************************************
import { useSwrMutationApi } from 'src/hooks/useSwrApi';

/**
 * @description SWR mutation: POST add skill to resume.
 * @param skillId - string. The skill ID.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param successCallback - function. The callback to call on success.
 * @param successToastText - string. The text to show in the success toast.
 */
export const useSwrResumeSkillAdd = (skillId: string, locale: string = 'ro', successCallback?: () => void, successToastText?: string) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, undefined>({
    url: `/v1/resume/skill/add/${skillId}`,
    locale,
    fetcherConfig: {
      method: 'POST',
    },
    apiOptions: {
      onSuccess: () => {
        // Callback
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      successToastText,
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
