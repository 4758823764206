import { useTranslation } from 'next-i18next';
import { CustomBiaEvaluate, CustomBiaOutline } from '@components/common/Icons';
import { useEffect, useState } from 'react';
import { TailwindSpinner } from '@components/common';
import { useSwrJobEvaluateBia } from '@hooks/useSwrApi/endpoints/job/useSwrJobEvaluateBia';
import { GenericV1ErrorsArray } from '@type/v1-api-types';
import { useBjModal } from '@hooks/common/useBjModal';
import { AiEvaluationCv } from '@components/features/modals/employer/AiEvaluationCvModal';
import { AiEvaluation452Modal } from '@components/features/modals/employer/AiEvaluation452Modal';
import { useSearchParams } from 'next/navigation';
import { useAuthClient } from '@hooks/common/useAuthClient/useAuthClient';

import { route } from '@services/symfony';
import { BuyNeuroCandidateModal } from '@components/features/modals/buy-neuro-candidate/BuyNeuroCandidateModal';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';

/**
 * @description Evaluate CV with BIA for the current job.
 * - **job** - ApiJobDetailOk
 */
export interface EvaluateProps {
  jobSlug: string;
  locale : Locale;
  mutateUserDetail?: () => void,
  currentEvaluation?: ApiSchema<'ApplicationEvaluation'>;
}

/**
 * @description Job Detail Summary.
 * - **job** - ApiJobDetailOk
 */
export const AiEvaluateProfile = (props: EvaluateProps) => {
  const {
    jobSlug, locale, currentEvaluation, mutateUserDetail,
  } = props;
  // Translation
  const { t } = useTranslation('common');

  const { isAuth, redirectToLogin } = useAuthClient();


  const renderIconByScore = (score: number) => {
    if (score >= 0 && score <= 49) {
      return <div className="relative top-1 size-3 min-w-3 rounded-full bg-error" />;
    }
    if (score > 49 && score <= 69) {
      return <div className="relative top-1 size-3 min-w-3 rounded-full bg-warning" />;
    }
    return <div className="relative top-1 size-3 min-w-3 rounded-full bg-secondary" />;
  };

  const [evaluation, setEvaluation] = useState<ApiSchema<'ResumeEvaluation'> | undefined>(currentEvaluation);

  useEffect(() => {
    setEvaluation(currentEvaluation);
  }, [currentEvaluation]);


  const { isOpen: evaluationModal, toggleModal: toggleEvaluationModal } = useBjModal();
  const { isOpen: error452Modal, toggleModal: toggleError452Modal } = useBjModal();
  const { isOpen: error453Modal, toggleModal: toggleError453Modal } = useBjModal();

  const successCallbackResponse = (res: ApiSchema<'ResumeEvaluation'>) => {
    setEvaluation(res);
    if (mutateUserDetail) {
      void mutateUserDetail();
    }
    toggleEvaluationModal();
  };

  const errorCallbackResponse = (response:GenericV1ErrorsArray) => {
    if (response.errors[0].code === 452) {
      toggleError452Modal();
    }

    if (response.errors[0].code === 453) {
      toggleError453Modal();
    }
  };

  const {
    trigger, isMutating,
  } = useSwrJobEvaluateBia(jobSlug, locale, successCallbackResponse, errorCallbackResponse);

  const searchParams = useSearchParams();

  const afterEvaluation = searchParams?.get('afterEvaluation');

  const createEvaluationEvent = () => {
    sendGoogleTagEvent('job_detail_bia_evaluate_click', 'Custom');
    if (isAuth) {
      void trigger();
    } else {
      redirectToLogin(route('app_job_detail', { slug: jobSlug }));
    }
  };

  // Render component
  // ************************************
  return (
    <section className="mt-10 border border-transparent border-t-input py-5  ">
      {(!evaluation && !afterEvaluation)
        && (
        <div>
          <div className="mb-5 flex items-start gap-5">
            <CustomBiaEvaluate className="min-w-15" />
            <div>
              <div className="mb-1 font-bold">{t('global.evaluate.cv.by.job.title')}</div>
              <div>{t('global.evaluate.cv.by.job.desc')}</div>
            </div>
          </div>

          <div
            role="presentation"
            onClick={() => createEvaluationEvent()}
            className="cursor-pointer rounded-full bg-surface px-6 py-2.5 text-center font-semibold text-primary transition-all duration-300 hover:border-primary hover:bg-surface-50"
          >
            {isMutating ? (
              <TailwindSpinner className="mx-auto size-5" />
            ) : (
              t('global.evaluate.button')
            )}
          </div>
        </div>
        )}

      <AiEvaluation452Modal isOpen={error452Modal} toggleModal={toggleError452Modal} />
      <BuyNeuroCandidateModal
        isOpen={error453Modal}
        toggleModal={toggleError453Modal}
        jobSlug={jobSlug}
        successCallback={mutateUserDetail}
      />

      {evaluation && (
        <>
          <AiEvaluationCv isOpen={evaluationModal} toggleModal={toggleEvaluationModal} evaluation={evaluation} />

          <div className="rounded-md bg-surface p-4">
            <div className="mb-4 flex items-center">
              <CustomBiaOutline className="mr-4 size-5 min-w-5 fill-ink" />

              <div className="mb-1 pr-10 font-bold">
                {t('bia.job.evaluate.title')}
              </div>
            </div>
            <p className="text-md font-thin">
              {evaluation?.summary}
            </p>

            <div
              role="presentation"
              className="my-2 cursor-pointer font-thin underline"
              onClick={toggleEvaluationModal}
            >
              {t('bia.job.evaluate.summary.detailed')}
            </div>

            <div className="flex items-start gap-2">

              {evaluation?.score && renderIconByScore(evaluation?.score)}

              <div>
                <div>
                  {t('bia.job.evaluate.general.scor')}
                  {' '}
                  {evaluation?.score}
                  / 100
                </div>

                {evaluation?.position
                && (
                <div className="text-sm font-thin">
                  {evaluation.position}
                </div>
                )}

              </div>
            </div>
          </div>
        </>
      )}

      {evaluation?.reevaluationNeeded
        && (
          <div
            role="presentation"
            onClick={() => createEvaluationEvent()}
            className="mt-2 cursor-pointer rounded-full bg-surface px-6 py-2.5 text-center font-semibold text-primary transition-all duration-300 hover:border-primary hover:bg-surface-50"
          >
            {isMutating ? (
              <TailwindSpinner className="mx-auto size-5" />
            ) : (
              t('bia.job.reevaluate.button')
            )}
          </div>
        )}

      <div className="mt-2 pl-3 text-xs text-ink-medium">
        {'* '}
        {t('job.evaluate.position.comeback')}
      </div>


    </section>
  );
};
