'use client';

import { useTranslation } from 'next-i18next';
import { Button, Modal } from '@components/common';
import { FlagIcon } from '@heroicons/react/24/outline';
import { AiEvaluationFeedbackModal } from '@components/features/modals/ai-evaluation-feedback/AiEvaluationFeedbackModal';
import { useBjModal } from '@hooks/common/useBjModal';
import { XMarkIcon } from '@heroicons/react/16/solid';

interface AiEvaluationModalProps {
  isOpen: boolean,
  toggleModal: () => void,
  evaluation: ApiSchema<'ResumeEvaluation'>,
}
/**
 * @description Renders the discount amounts modal.
 * - **isOpen**: If true, the modal will be displayed.
 * - **toggleModal**: Function to toggle the modal.
 * - **evaluation**: The modal details
 */
export const AiEvaluationCv = (props: AiEvaluationModalProps) => {
  // Props destructuring
  const {
    isOpen,
    toggleModal,
    evaluation,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  const { isOpen: feedbackModal, toggleModal: toggleFeedbackModal } = useBjModal();

  const headerContent = (
    <div className="flex items-center justify-between border-b px-6 py-4 font-bold ">
      {t('global.ai.evaluation.modal.title')}
      {evaluation?.id && (
        <div className="flex items-center gap-2">
          <AiEvaluationFeedbackModal isOpen={feedbackModal} toggleModal={toggleFeedbackModal} identifier={evaluation.id} evaluation={evaluation} />
          <div className="hidden cursor-pointer justify-center text-sm text-primary md:flex" role="presentation" onClick={toggleFeedbackModal}>
            <FlagIcon className="mr-2 inline size-6" />
            {t('bia.evaluate.feedback')}
          </div>
          <Button color="light" size="sm" className="-mr-2 ml-3 !rounded-full !p-2" onClick={toggleModal}>
            <XMarkIcon className="size-4" />
          </Button>
        </div>
      )}
    </div>
  );

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      size="md"
      header={headerContent}
      body={(
        <div className="p-5">
          <div className="mb-3 text-md">
            <div className="mb-5 flex cursor-pointer justify-start text-sm text-primary md:hidden" role="presentation" onClick={toggleFeedbackModal}>
              <FlagIcon className="mr-2 inline size-6" />
              {t('bia.evaluate.feedback')}
            </div>
            <p className="font-bold">
              {t('bia.evaluate.overall.score')}
              <span className="font-normal">
                {' '}
                {Math.ceil(evaluation?.score ?? 0)}
                /100
              </span>
            </p>
          </div>
          <div className="mb-3 text-md">
            <p className="font-bold">{t('bia.evaluate.scores')}</p>
            <ul className="list-disc pl-5">
              <li>
                <p className="text-xs font-bold">
                  {t('bia.evaluate.scores.skills')}
                  {' '}
                  <span className="font-normal">
                    {Math.ceil(evaluation?.skillsMatchScore ?? 0)}
                    /100
                  </span>
                </p>
                <p className="text-xs">
                  {evaluation?.skillsMatchExplanation}
                </p>
              </li>
              {evaluation?.experienceMatchScore && (
              <li>
                <p className="text-xs font-bold">
                  {t('bia.evaluate.scores.experience')}
                  {' '}
                  <span className="font-normal">
                    {Math.ceil(evaluation?.experienceMatchScore ?? 0)}
                    /100
                  </span>
                </p>
                <p className="text-xs">
                  {evaluation?.experienceMatchExplanation}
                </p>
              </li>
              )}
              <li>
                <p className="text-xs font-bold">
                  {t('bia.evaluate.scores.education')}
                  {' '}
                  <span className="font-normal">
                    {Math.ceil(evaluation?.educationMatchScore ?? 0)}
                    /100
                  </span>
                </p>
                <p className="text-xs">
                  {evaluation?.educationMatchExplanation}
                </p>
              </li>
              <li>
                <p className="text-xs font-bold">
                  {t('bia.evaluate.scores.location')}
                  {' '}
                  <span className="font-normal">
                    {Math.ceil(evaluation?.locationMatchScore ?? 0)}
                    /100
                  </span>
                </p>
                <p className="text-xs">
                  {evaluation?.locationMatchExplanation}
                </p>
              </li>
            </ul>

          </div>
          <div className="mb-3 text-md">
            <p className="font-bold">{t('bia.evaluate.strengths')}</p>
            <p>
              {evaluation?.strengths}
            </p>
          </div>
          <div className="text-sm">
            <p className="font-bold">{t('bia.evaluate.weaknesses')}</p>
            <p>
              {evaluation?.weaknesses}
            </p>
          </div>

          <div className="my-2 border-t pt-2 text-xs italic text-ink-medium">
            {t('bia.evaluate.information')}
          </div>
        </div>
      )}
    />
  );
};
