import { useMemo } from 'react';
import { generateNumbersArray } from '@utils/arrays/generateNumbersArray';

/**
 * @description Job Detail Tags Placeholder.
 */
export const TagsPlaceholder = () => {
  const tagsItems = useMemo(
    () => generateNumbersArray(1, 8),
    [],
  );

  return (
    <div className="animate-pulse flex items-center flex-wrap gap-4">
      {tagsItems.map((index) => (
        <div key={`${index}-`} className="bg-slate-200 h-8 w-24 rounded-full" />
      ))}
    </div>
  );
};
