// Add credits to cart ****************************************************************************
// pathname: /v1/order/create-cart
// ************************************************************************************************
import { useSwrMutationApi } from '@hooks/useSwrApi';
import { ApiOrderCreditsPost, ApiOrderCreateCartOk } from '@type/v1-api-types/order';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { route } from '@services/symfony';

/**
 * @description Hook props
 */
interface UseSwrOrderCreateCart {
  locale: string,
  successCallback?: () => void,
  successRedirectPath?: string,
  addIdToSuccessRedirect?: boolean,
  afterLoginRedirectPath?: string,
}

/**
 * @description SWR mutation: get the cart id for the credits order.
 * - `locale` string - the locale to use for the request. Default 'ro'.
 * - `successCallback` function - the callback to call after success. Default undefined.
 * - `successRedirectPath` string - the path to redirect after success. Default undefined.
 * - `addIdToSuccessRedirect` boolean - if true, the id will be added to the successRedirectPath. Default false.
 * - `afterLoginRedirectPath` string - the path to redirect after login. Default undefined. If undefined, the user
 * will be redirected to the company offer page.
 */
export const useSwrOrderCreateCart = (props: UseSwrOrderCreateCart) => {
  // Props destructuring
  const {
    locale = 'ro',
    successCallback,
    successRedirectPath,
    addIdToSuccessRedirect = false,
    afterLoginRedirectPath,
  } = props;

  // Router
  const { router, withLocale } = useExtendedRouter();

  // SWR wrapper
  const {
    data, error, trigger, isMutating, reset,
  } = useSwrMutationApi<ApiOrderCreateCartOk, ApiOrderCreditsPost>({
    url: '/v1/order/create-cart',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: (newData) => {
        if (successCallback) successCallback();
        if (successRedirectPath) {
          void router.push(`${successRedirectPath}${addIdToSuccessRedirect ? `/${newData?.id}` : ''}`);
        }
      },
      onError: (newError) => {
        if (newError?.errors[0]?.code === 401) {
          void router.push(route('login', { _target_path: encodeURIComponent(afterLoginRedirectPath ? withLocale(afterLoginRedirectPath) : withLocale('/company/offer')) }));
        }
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // return data
  return {
    data,
    error,
    trigger,
    isMutating,
    reset,
  };
};
